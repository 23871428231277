import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import 'font-awesome/css/font-awesome.min.css';

import Alert from '../components/alert';
import Header from '../components/header';
import LargePhoto from '../components/large_photo';
import EventList from '../components/event_list';
import Connect from '../components/connect';
import FindUs from '../components/find_us';
import Footer from '../components/footer';
import Welcome from '../components/welcome';
import Music from '../components/music';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      events: []
    };

    this.addAlert = this.addAlert.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
  }

  addAlert(theme, message) {
    this.setState({
      alert: {
        theme,
        message
      }
    });
  }

  dismissAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    const { bandPhoto, socialMedia, emailAddress } = this.props;
    const { events } = this.state;

    const eventsContentColumn = {
      zIndex: 1
    };

    const eventsContentWrapper = {
      //
    };

    const eventsImageColumn = {
      left: '-100px',
      zIndex: -1
    };

    const eventsImageWrapper = {
      width: 'calc(100% + 100px)'
    };

    return (
      <div>
        {this.state.alert && (
          <div className="container fixed-top">
            <div className="row">
              <div className="col-md-10 offset-md-1 mt-4">
                <Alert theme={this.state.alert.theme} message={this.state.alert.message} dismiss={this.dismissAlert} />
              </div>
            </div>
          </div>
        )}
        <header className="container">
          <Header socialMedia={socialMedia} />
          <LargePhoto photo={bandPhoto} />
        </header>
        <main className="text-light mb-4">
          <article className="welcome">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <header className="mt-4">
                    <span className="h5">Welcome</span>
                  </header>
                  <Welcome socialMedia={socialMedia} emailAddress={emailAddress} />
                </div>
              </div>
            </div>
          </article>

          <article className="music">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 order-lg-2 order-2">
                  <div>
                    <header className="mt-4">
                      <span className="h5">Music</span>
                    </header>
                    <Music showVideo={true} />
                  </div>
                </div>
                <div className="col-lg-5 order-lg-1 order-1 d-lg-block d-none">
                  <div>
                    <img className="img-fluid" src="/images/IMG_8124.jpg" alt="Jim (Guitar)" />
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="events mt-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-1 order-2" style={eventsContentColumn}>
                  <div style={eventsContentWrapper}>
                    <header className="mt-4">
                      <span className="h5">Events</span>
                    </header>
                    <EventList events={events} />
                  </div>
                </div>
                <div className="col-lg-4 order-lg-2 d-lg-block d-none" style={eventsImageColumn}>
                  <div style={eventsImageWrapper}>
                    <img className="img-fluid" src="/images/IMG_8126.jpg" alt="Ernie (Sax)" />
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <header className="mt-4">
                    <span className="h5">Contact</span>
                  </header>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <FindUs emailAddress={emailAddress} socialMedia={socialMedia} />
                </div>
                <div className="col-lg-7">
                  <Connect
                    socialMedia={socialMedia}
                    addAlert={this.addAlert}
                    reCaptchaSiteKey={this.props.reCaptchaSiteKey}
                  />
                </div>
              </div>
            </div>
          </article>
        </main>
        <footer className="container mb-3">
          <img src="/images/IMG_8895.jpg" className="img-fluid" alt="Performance" />
          <Footer />
        </footer>
      </div>
    );
  }

  componentDidMount() {
    const url = `${this.props.restApiEndpoint}/events.json`;
    fetch(url, {
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          events: data
        });
      });
  }
}

Home.propTypes = {
  bandPhoto: PropTypes.string,
  restApiEndpoint: PropTypes.string,
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  emailAddress: PropTypes.string,
  reCaptchaSiteKey: PropTypes.string.isRequired,
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      site: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default Home;
