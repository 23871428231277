import React, { Component } from 'react';
import moment from 'moment';
import * as types from '../types';
import { ISO_FORMAT } from '../time';

class EventListItem extends Component {
  render() {
    const { event } = this.props;

    const eventId = event.id;
    const eventName = event.name;
    const eventStartTime = moment(event.start_time, ISO_FORMAT);
    const eventCity = event.place && event.place.location && event.place.location.city;
    const eventState = event.place && event.place.location && event.place.location.state;

    const eventLocationShort = eventCity;
    const eventLocationLong = [eventCity, eventState].filter((word) => !!word).join(', ');
    const eventUrl = `https://www.facebook.com/events/${eventId}`;

    return (
      <li className="d-flex flex-row align-items-center justify-content-start bg-dark p-3 mb-2" key={eventId}>
        <div className="bg-info py-3" style={{ width: '3.5rem', textAlign: 'center' }}>
          {eventStartTime.format('M/D')}
        </div>
        <div className="flex-fill w-25 ml-3">
          {eventName} @{eventStartTime.format('h:mma').replace(':00', '')}
        </div>
        <div className="flex-fill w-25 ml-3">
          <span className="d-block d-sm-none">{eventLocationShort}</span>
          <span className="d-none d-sm-block">{eventLocationLong}</span>
        </div>
        <a href={eventUrl} target="_blank" rel="noopener noreferrer" className="btn btn-secondary ml-auto">
          <span className="d-block d-sm-none">Info</span>
          <span className="d-none d-sm-block">More Info</span>
        </a>
      </li>
    );
  }
}

EventListItem.propTypes = {
  event: types.Event
};

export default EventListItem;
