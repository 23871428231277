const reactAppEnvironment = process.env.REACT_APP_ENV || 'development';

const config = {
  global: {
    bandPhoto: '/images/nightowls2.jpg',
    facebookUrl: 'https://www.facebook.com/sdnightowls',
    instagramUrl: 'https://www.instagram.com/sdnightowls',
    youtubeUrl: 'https://www.youtube.com/channel/UCyKZ26R-78QABBGi0V9Ouow',
    emailAddress: 'info@nightowlsband.com',
    reCaptchaSiteKey: '6LfvzXYcAAAAAEI1IGmNZoDr8P7ZBLuaemyfqPcj',
    socialMedia: [
      {
        site: 'Facebook',
        icon: 'facebook-square',
        label: '@sdnightowls',
        url: 'https://www.facebook.com/sdnightowls/'
      },
      {
        site: 'Instagram',
        icon: 'instagram',
        label: '@sdnightowls',
        url: 'https://www.instagram.com/sdnightowls/'
      }
    ]
  },
  development: {
    // restApiEndpoint: 'https://uhjnfxyph0.execute-api.us-east-1.amazonaws.com/prod'
    restApiEndpoint: 'http://localhost:4000'
  },
  test: {
    restApiEndpoint: ''
  },
  production: {
    restApiEndpoint: 'https://dupcwkd45g.execute-api.us-east-1.amazonaws.com/prod'
  }
};

export default {
  ...config.global,
  ...config[reactAppEnvironment]
};
