import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import 'font-awesome/css/font-awesome.min.css';

class Alert extends Component {
  render() {
    const { theme, message, dismiss } = this.props;
    return (
      <article className={`alert alert-${theme} fade show`} role="alert">
        {message}
        <button type="button" className="close" aria-label="Close" onClick={dismiss}>
          <span aria-hidden="true">&times;</span>
        </button>
      </article>
    );
  }
}

Alert.propTypes = {
  theme: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired
};

export default Alert;
