import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import 'font-awesome/css/font-awesome.min.css';

class FindUs extends Component {
  render() {
    const { socialMedia, emailAddress } = this.props;

    return (
      <article>
        <header>
          <h1>Where to find us</h1>
        </header>
        <section className="mt-4">
          <p>Send us an email or connect on social media.</p>
          <address>
            <strong className="text-light mr-3">Email</strong>
            <a className="text-warning" href={`mailto:${emailAddress}`}>
              {emailAddress}
            </a>
          </address>
          {socialMedia && socialMedia.length > 0 && (
            <ul className="list-unstyled list-inline">
              {socialMedia.map((data) => {
                const { site, label, icon, url } = data;
                return (
                  <li className="list-inline-item mr-3" key={site}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-warning h3"
                      title={site + label}
                    >
                      <i className={`fa fa-lg fa-${icon}`} />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </section>
      </article>
    );
  }
}

FindUs.propTypes = {
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      site: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string
    })
  ),
  emailAddress: PropTypes.string.isRequired
};

export default FindUs;
