import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <article>
        <hr />
        <small className="text-muted">
          Copyright &copy;2022 All rights reserved | This template is made with&nbsp;
          <i className="fa fa-heart-o" aria-hidden="true"></i> by&nbsp;
          <a className="link" href="https://colorlib.com" target="_blank" rel="noopener noreferrer">
            Colorlib
          </a>
        </small>
      </article>
    );
  }
}

Footer.propTypes = {};

export default Footer;
