import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class LargePhoto extends Component {
  render() {
    const { photo } = this.props;

    return (
      <article>
        <img src={photo} className="img-fluid" alt="The Band" />
      </article>
    );
  }
}

LargePhoto.propTypes = {
  photo: PropTypes.string
};

export default LargePhoto;
