export function omit(obj, ...attributes) {
  return Object.entries(obj)
    .filter(([key, _value]) => {
      return !attributes.includes(key);
    })
    .reduce((accum, [key, value]) => {
      return {
        ...accum,
        [key]: value
      };
    }, {});
}

/*
 * https://www.cluemediator.com/how-to-dynamically-load-script-with-callback-in-javascript
 * https://www.cluemediator.com/how-to-implement-recaptcha-v3-in-react
 */
export function loadScriptByURL(id, url, callback) {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
}
