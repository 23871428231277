import React from 'react';
import { omit } from '../util';

export function Form(props) {
  return <form {...props}>{props.children}</form>;
}

export function FormGroup(props) {
  return (
    <div className="form-group" {...props}>
      {props.children}
    </div>
  );
}

export function FormControlInput(props) {
  return <input className="form-control" {...props} />;
}

export function FormControlTextArea(props) {
  return (
    <textarea className="form-control" {...props}>
      {props.children}
    </textarea>
  );
}

export function TextControl(props) {
  return (
    <FormGroup>
      <FormControlInput type="text" {...props} />
    </FormGroup>
  );
}

export function TextAreaControl(props) {
  return (
    <FormGroup>
      <FormControlTextArea {...props}>{props.children}</FormControlTextArea>
    </FormGroup>
  );
}

export function EmailControl(props) {
  return (
    <FormGroup>
      <FormControlInput type="email" {...props} />
    </FormGroup>
  );
}

export const ButtonStyle = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
  SUCCESS: 'btn-success',
  DANGER: 'btn-danger',
  WARNING: 'btn-warning',
  INFO: 'btn-info',
  LIGHT: 'btn-light',
  DARK: 'btn-dark',
  LINK: 'btn-link'
};

export function SubmitButton(props) {
  const classes = ['btn'];

  if (props.buttonStyle) {
    classes.push(props.buttonStyle);
  }

  if (props.marginBottom) {
    classes.push(`mb-${props.marginBottom}`);
  }

  const className = classes.join(' ');
  const buttonProps = omit(props, 'buttonStyle', 'marginBottom');

  return (
    <button type="submit" className={className} {...buttonProps}>
      {props.children}
    </button>
  );
}
