import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class Music extends Component {
  render() {
    const { showVideo } = this.props;

    return (
      <article>
        <header>
          <h1>The Band</h1>
        </header>
        <section>
          <p>
            The Night Owls started as a group of teachers from North County, San Diego with a shared interest in music,
            and expanded by connecting with other musicians in the north county music scene. They eventually made it out
            of the garage and into local bars, breweries, and special events. Their domain is north of the 56 and south
            of the 76.
          </p>
          <p>
            The band has an ever-growing catalog of cover songs, ranging from the 60s to the present, with an emphasis
            on rock and soul. If you like to rock and like to dance, you'll enjoy this band.
          </p>
          {showVideo && (
            <div>
              <p className="lead text-secondary">Watch the video for clips from past performances!</p>
              <div className="text-center">
                <video className="img-fluid" poster="/images/video_poster_play.jpg" width="90%" controls>
                  <source
                    src="/videos/The Night Owls at Inzane Brewing Co on 3-9-2024.mp4"
                    type="video/mp4"
                    codecs=""
                  />
                </video>
              </div>
            </div>
          )}
        </section>
      </article>
    );
  }
}

Music.propTypes = {
  showVideo: PropTypes.bool
};

export default Music;
