import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class Header extends Component {
  render() {
    const { socialMedia } = this.props;

    return (
      <article>
        <nav className="navbar navbar-dark bg-black">
          <span className="navbar-brand">the Night Owls</span>
          <div className="d-flex flex-row">
            <ul className="navbar-nav flex-row">
              {socialMedia.map((social) => (
                <li className="nav-item" key={social.site}>
                  <a
                    className="nav-link px-2 text-info"
                    href={social.url}
                    title={social.site + social.label}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className={`fa fa-lg fa-${social.icon}`}></span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </article>
    );
  }
}

Header.propTypes = {
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      site: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default Header;
