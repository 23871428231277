import { PropTypes } from 'prop-types';

export const Event = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  place: PropTypes.shape({
    name: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string
    }),
    id: PropTypes.string
  }),
  start_time: PropTypes.string
});

export const EventList = PropTypes.arrayOf(Event);
