import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class Welcome extends Component {
  render() {
    const { socialMedia, emailAddress } = this.props;

    const facebook = socialMedia.find((social) => social.site === 'Facebook');
    const instagram = socialMedia.find((social) => social.site === 'Instagram');

    return (
      <article>
        <header>
          <h1>About Us</h1>
        </header>
        <p className="lead text-secondary">
          The Night Owls specialize in 60s and 70s Soul music and Rock &amp; Roll, with a touch of Blues and Americana.
        </p>
        <p>
          <span className="badge badge-warning">Fans</span> Follow us on{' '}
          <a className="link" href={facebook.url} target="_blank" rel="noopener noreferrer">
            {facebook.site}
            {facebook.label}
          </a>{' '}
          or{' '}
          <a className="link" href={instagram.url} target="_blank" rel="noopener noreferrer">
            {instagram.site}
            {instagram.label}
          </a>{' '}
          for photos, videos and info on upcoming events
        </p>
        <p>
          <span className="badge badge-warning">Promoters</span> View videos on{' '}
          <a className="link" href={instagram.url} target="_blank" rel="noopener noreferrer">
            {instagram.site}
            {instagram.label}
          </a>
          , or reach out to us via email at{' '}
          <a className="link" href={`mailto:${emailAddress}`} target="_blank" rel="noopener noreferrer">
            {emailAddress}
          </a>
        </p>
      </article>
    );
  }
}

Welcome.propTypes = {
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      site: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  emailAddress: PropTypes.string.isRequired
};

export default Welcome;
