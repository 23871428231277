import React, { Component } from 'react';
import EventListItem from './event_list_item';
import * as types from '../types';
import moment from 'moment';
import { ISO_FORMAT } from '../time';

class EventList extends Component {
  static COLLAPSE_THRESHOLD = 60; // days

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };

    this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    console.log('toggle collapse!');
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const { events } = this.props;
    const { collapsed } = this.state;

    const threshold = moment().add(EventList.COLLAPSE_THRESHOLD, 'days');
    const eventsSooner = events.filter((event) => moment(event.start_time, ISO_FORMAT).isBefore(threshold));
    const eventsLater = events.filter((event) => moment(event.start_time, ISO_FORMAT).isAfter(threshold));

    return (
      <article>
        <header>
          <h1>Upcoming Shows</h1>
        </header>
        <section className="mt-4 mb-4">
          <ul className="list-unstyled mb-0">
            {eventsSooner.map((event) => (
              <EventListItem key={event.id} event={event} />
            ))}
          </ul>
          <ul className="list-unstyled mb-0" hidden={collapsed}>
            {eventsLater.map((event) => (
              <EventListItem key={event.id} event={event} />
            ))}
          </ul>
          {!!eventsLater.length && (
            <div className="d-flex justify-content-center mt-4">
              <button type="button" className="btn btn-secondary" onClick={() => this.toggleCollapse()}>
                {collapsed ? 'Show More' : 'Show Less'}
              </button>
            </div>
          )}
        </section>
      </article>
    );
  }
}

EventList.propTypes = {
  events: types.EventList
};

export default EventList;
