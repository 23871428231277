import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'font-awesome/css/font-awesome.min.css';

import { sendMessage } from '../actions';
import { Form, TextControl, TextAreaControl, EmailControl, SubmitButton, ButtonStyle } from './form';
import { loadScriptByURL } from '../util';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.initialState = { sending: false, name: '', email: '', subject: '', message: '' };
    this.state = this.initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${this.props.reCaptchaSiteKey}`,
      function () {
        console.log('reCAPTCHA script loaded!');
      }
    );
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ sending: true });

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(this.props.reCaptchaSiteKey, { action: 'sendmessage' }).then((token) => {
        sendMessage({
          name: this.state.name,
          email: this.state.email,
          subject: this.state.subject,
          message: this.state.message,
          grecaptchaResponse: token
        })
          .then(() => {
            this.props.addAlert('success', 'Thanks for reaching out!');
            this.setState(this.initialState);
          })
          .catch(() => {
            this.props.addAlert('danger', 'Failed to send message!');
          });
      });
    });
  }

  render() {
    return (
      <article>
        <header>
          <h1>Send us a message</h1>
        </header>
        <section className="mt-4">
          <Form onSubmit={this.handleSubmit}>
            <TextControl name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange} required />
            <EmailControl
              name="email"
              placeholder="E-mail"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
            <TextControl
              name="subject"
              placeholder="Subject"
              value={this.state.subject}
              onChange={this.handleChange}
              required
            />
            <TextAreaControl
              name="message"
              placeholder="Message"
              value={this.state.message}
              onChange={this.handleChange}
              required
            />
            <SubmitButton buttonStyle={ButtonStyle.INFO} marginBottom={2} disabled={this.state.sending}>
              {this.state.sending || <span>Send Message</span>}
              {this.state.sending && (
                <span>
                  <span className="spinner-border spinner-border-sm m-1" role="status" aria-hidden="true"></span>
                  Sending...
                </span>
              )}
            </SubmitButton>
          </Form>
        </section>
      </article>
    );
  }
}

Connect.propTypes = {
  addAlert: PropTypes.func.isRequired,
  reCaptchaSiteKey: PropTypes.string.isRequired
};

export default Connect;
