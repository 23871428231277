import data from './data';

export async function sendMessage(message) {
  const url = `${data.restApiEndpoint}/send`;

  const response = await fetch(url, {
    mode: 'cors',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(message)
  });

  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve();
    } else {
      reject();
    }
  });
}
